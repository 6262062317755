import _ from 'lodash';
import { makeCell } from './gridUtill';
let fields = [];
let columns = [];
makeCell(
  1,
  [
    {
      field: 'code',
      alias: 'Code Grade',
      indexExclusive: true,
    },
  ],
  fields,
  columns,
);
makeCell(
  1,
  [
    {
      field: 'ww',
      alias: 'w/w',
      indexExclusive: true,
    },
  ],
  fields,
  columns,
);
makeCell(
  8,
  [
    {
      field: 'solvent',
      alias: 'solvent',
      indexExclusive2: true,
    },
    {
      field: 'solventVol',
      alias: 'vol%',
      excelKey: 'vol%_',
      indexExclusive2: true,
    },
  ],
  fields,
  columns,
);
makeCell(
  3,
  [
    {
      field: 'salt',
      alias: 'salt',
      indexExclusive2: true,
    },
    {
      field: 'saltVol',
      alias: 'M,wt%',
      excelKey: 'M,wt%_',
      indexExclusive2: true,
    },
  ],
  fields,
  columns,
);
makeCell(
  10,
  [
    {
      field: 'add',
      alias: 'add',
      indexExclusive2: true,
    },
    {
      field: 'addVol',
      alias: 'wt%',
      excelKey: 'wt%_',
      indexExclusive2: true,
    },
  ],
  fields,
  columns,
);
makeCell(
  1,
  [
    {
      field: 'mass_code',
      alias: '양산 자재코드',
      indexExclusive: true,
    },
    {
      field: 'product_code',
      alias: '제품 자재코드',
      indexExclusive: true,
    },
  ],
  fields,
  columns,
);
makeCell(
  8,
  [
    {
      field: 'r_solvent',
      alias: 'solvent_',
      indexExclusive2: true,
    },
    {
      field: 'r_solventVol',
      alias: 'vol%_',
      // alias: 'wt%',
      excelKey: 'r_solvent_wt%_',
      indexExclusive2: true,
    },
  ],
  fields,
  columns,
);
makeCell(
  3,
  [
    {
      field: 'r_salt',
      alias: 'salt_',
      indexExclusive2: true,
    },
    {
      field: 'r_saltvol',
      alias: 'M,wt%_',
      excelKey: 'r_salt_wt%_',
      indexExclusive2: true,
    },
  ],
  fields,
  columns,
);
makeCell(
  10,
  [
    {
      field: 'r_add',
      alias: 'add_',
      indexExclusive2: true,
    },
    {
      field: 'r_addVol',
      alias: 'wt%_',
      excelKey: 'r_add_wt%_',
      indexExclusive2: true,
    },
  ],
  fields,
  columns,
);
makeCell(
  1,
  [
    {
      field: 'sum',
      alias: 'Sum',
      indexExclusive: true,
    },
  ],
  fields,
  columns,
);

const rowSet = _.reduce(
  fields,
  (a, v) => {
    if (v.excelKey) {
      a.push({
        key: v.fieldName,
        value: v.excelKey,
      });
    }
    return a;
  },
  [],
);

export { fields, columns, rowSet };
