<template>
  <div>
    <!-- <h3>납품일보</h3> -->
    <SetPopup ref="confirm" />
    <v-form ref="form" lazy-validation>
      <div class="filter-box filter-box--row wrapperSpace mt-2" style="position: relative">
        <div style="padding-bottom: 10px">
          <ul class="filter-list_free">
            <li style="width: 180px">
              <h4>Lot No</h4>
              <v-text-field outlined dense placeholder="" :rules="[this.validSet.commonCodeHipen]" v-model="param.lotNo" @keyup.enter="search"></v-text-field>
            </li>
            <li style="width: 180px">
              <h4>Code Grade</h4>
              <v-text-field outlined dense v-model="param.code" :rules="[this.validSet.commonCodeHipen]" @keyup.enter="search"></v-text-field>
            </li>
            <li style="width: 180px">
              <h4>요청업체명</h4>
              <v-text-field outlined dense placeholder="" v-model="param.request_company" :rules="[this.validSet.company]" @keyup.enter="search"></v-text-field>
            </li>
            <li style="width: 180px">
              <h4>요청자</h4>
              <v-text-field outlined dense :rules="[this.validSet.name]" v-model="param.request_name" @keyup.enter="search"></v-text-field>
            </li>
            <li style="width: 290px">
              <h4>제조예정일</h4>
              <div class="dp--row">
                <v-menu v-model="picker1" :close-on-content-click="true" :nudge-right="0" :nudge-top="20" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="param.from_produce_due_date" placeholder="" append-icon="mdi-calendar" outlined dense readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker no-title scrollable @input="menu = false" v-model="param.from_produce_due_date"></v-date-picker>
                </v-menu>
                <i class="ml-1 mr-1">~</i>
                <v-menu v-model="picker2" :close-on-content-click="true" :nudge-right="0" :nudge-top="20" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="param.to_produce_due_date" placeholder="" append-icon="mdi-calendar" outlined dense readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker no-title scrollable @input="menu = false" v-model="param.to_produce_due_date"></v-date-picker>
                </v-menu>
              </div>
            </li>
            <li style="width: 290px">
              <h4>출하예정일</h4>
              <div class="dp--row">
                <v-menu v-model="picker3" :close-on-content-click="true" :nudge-right="0" :nudge-top="20" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="param.from_out_due_date" placeholder="" append-icon="mdi-calendar" outlined dense readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker no-title @input="menu = false" v-model="param.from_out_due_date"></v-date-picker>
                </v-menu>
                <i class="ml-1 mr-1">~</i>
                <v-menu v-model="picker4" :close-on-content-click="true" :nudge-right="0" :nudge-top="20" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="param.to_out_due_date" placeholder="" append-icon="mdi-calendar" outlined dense readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker no-title @input="menu = false" v-model="param.to_out_due_date"></v-date-picker>
                </v-menu>
              </div>
            </li>
          </ul>
          <ul v-if="showSearchOptions" class="filter-list_free" style="padding-top: 10px">
            <li style="width: 180px">
              <h4>요청 자재코드</h4>
              <v-text-field outlined dense placeholder="" v-model="param.request_code" @keyup.enter="search"></v-text-field>
            </li>
            <li style="width: 180px">
              <h4>수령자</h4>
              <v-text-field outlined dense v-model="param.pick_name" @keyup.enter="search"></v-text-field>
            </li>
            <li style="width: 290px">
              <h4>요청일자</h4>
              <div class="dp--row">
                <v-menu v-model="picker5" :close-on-content-click="true" :nudge-right="0" :nudge-top="20" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="param.from_req_date" placeholder="" append-icon="mdi-calendar" outlined dense readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker no-title @input="menu = false" v-model="param.from_req_date"></v-date-picker>
                </v-menu>
                <i class="ml-1 mr-1">~</i>
                <v-menu v-model="picker6" :close-on-content-click="true" :nudge-right="0" :nudge-top="20" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="param.to_req_date" placeholder="" append-icon="mdi-calendar" outlined dense readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker no-title @input="menu = false" v-model="param.to_req_date"></v-date-picker>
                </v-menu>
              </div>
            </li>
          </ul>
        </div>
        <div class="btn-box">
          <v-card-actions>
            <v-btn depressed outlined class="btn-reset" @click="reset">초기화</v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-btn depressed class="btn-search" @click="search">검색</v-btn>
          </v-card-actions>
        </div>
        <div @click="toggleSearchOptions" style="cursor: pointer; position: absolute; bottom: -15px; display: flex; width: 100px; justify-content: center; border: 1px solid #d0d0d0; background-color: #efefef; border-radius: 5px; left: 50%; transform: translateX(-50%)">
          <img src="@/assets/img/down.png" style="width: 20px" :style="rotationStyle" />
        </div>
      </div>
      <div class="dp--row mb-2">
        <!-- <h4 class="mt-4 mb-2">목록</h4> -->
        <v-btn depressed color="primary" @click="exelDownload" style="width: 143px; margin-left: auto">엑셀 다운로드</v-btn>
      </div>
      <!-- <div class="wrapperSpace px-2">
        <div>
          <h4 class="mt-4 mb-2">목록</h4>
        </div>
        <v-btn
          depressed
          color="primary"
          @click="exelDownload"
          style="width: 143px"
          >엑셀 다운로드</v-btn
        >
      </div> -->
      
    </v-form>
    <RealGrid :domName="grid" ref="grid" :settings="settings" @changePage="loadData" @dbClick="dbClick" @click="click" />
  </div>
</template>
<script>
import { columns, fields, rows } from '@/assets/grid/dailyReport';
import { searchproduce } from 'api/sample/sample';
import { getExcel, getCoapdfFile, getMsdspdfFile, getReportFile2 } from 'api/file';
import { mapMutations } from 'vuex';
import SetPopup from '@/components/SetPopup.vue';
import RealGrid from '@/components/RealGrid.vue';
import validSet from '@/assets/valid';
import _ from 'lodash';
export default {
  data() {
    return {
      rotationDegrees: 0,
      showSearchOptions: false,
      // isRotated: false,
      isFetching: false,
      isDataLoaded: false,
      picker1: false,
      picker2: false,
      picker3: false,
      picker4: false,
      picker5: false,
      picker6: false,
      grid: 'delivaryReport',
      validSet,
      items: [],
      settings: {
        columns,
        fields,
        rows,
        height: 555,
        errorMessage: '요청된 내역이 없습니다',
        noneNo: true,
        fixCol: 2, // 컬럼 고정 번호
      },
      param: {
        lotNo: '',
        request_name: '',
        request_code: '',
        pick_name: '',
        pageSize: process.env.VUE_APP_DEFAULT_PAGE_LX_SIZE || 100000,
        code: '',
        request_company: '',
        produce_due_date: '',
        out_due_date: '',
        from_produce_due_date: '',
        to_produce_due_date: '',
        from_out_due_date: '',
        to_out_due_date: '',
        from_req_date: '',
        to_req_date: '',
      },
    };
  },
  watch: {
    param: {
      deep: true,
      handler: function () {
        this.currentPage = 1;
      },
    },
  },
  methods: {
    ...mapMutations('popup', ['SET_POPUP']),
    valid() {
      return this.$refs.form.validate();
    },
    exelDownload() {
      const data = this.$refs.grid.getCheckedRowExecl(this.settings.columns);
      if (data.length > 0) {
        getExcel(data, '납품일보');
      } else {
        this.openPopup('엑셀 다운로드할 행을 선택해주세요');
      }
    },
    openPopup(text, closable, cb) {
      this.SET_POPUP({
        title: '알림',
        height: 150,
        width: 300,
        text,
        closable,
      });
      this.$refs.confirm.openPopup(cb);
    },
    loadData(v) {
      this.search(v);
    },
    // search(v) {
    //   if (this.valid()) {
    //     if (_.isNumber(v)) {
    //       this.currentPage = v;
    //     }
    //     searchproduce({
    //       currentPage: this.currentPage,
    //       ...this.param,
    //     }).then((res) => {
    //       const response = res.data;
    //       const items = response.data.items;
    //       const page = response.data.params;
    //       this.items = items;
    //       this.$refs.grid.loadData(items, [
    //         "delivery_date",
    //         "delivery_due_date",
    //         "request_date",
    //         "produce_due_date",
    //         "produce_date",
    //         "out_date",
    //         "out_due_date",
    //       ]);
    //       this.$refs.grid.setPage(page);
    //       if (items.length === 0) {
    //         this.settings.errorMessage = "진행중인 사항이 없습니다";
    //       }
    //     });
    //   }
    // },

    gridsize () {
      var width1 = gridView.layoutByColumn("ProductId").cellWidth; 
    },

    async search(v) {
      if (!this.valid()) {
        return;
      }

      if (this.isFetching) return;
      this.isFetching = true;

      try {
        if (_.isNumber(v)) {
          this.currentPage = v;
        }
        const res = await searchproduce({
          currentPage: this.currentPage,
          viewName: 'delivery',
          ...this.param,
        });
        this.isDataLoaded = true;
        const response = res.data;
        const items = response.data.items;
        const page = response.data.params;
        this.items = items;
        let filterditems = items;
        // if (this.param.from_produce_due_date) {
        //   const from_date = new Date(this.param.from_produce_due_date);
        //   filterditems = filterditems.filter(
        //     (item) => new Date(item.produce_due_date) >= from_date
        //   );
        // }
        // if (this.param.to_produce_due_date) {
        //   const to_date = new Date(this.param.to_produce_due_date);
        //   filterditems = filterditems.filter(
        //     (item) => new Date(item.produce_due_date) <= to_date
        //   );
        // }
        // if (this.param.from_out_due_date) {
        //   const from_date = new Date(this.param.from_out_due_date);
        //   filterditems = filterditems.filter(
        //     (item) => new Date(item.out_due_date) >= from_date
        //   );
        // }
        // if (this.param.to_out_due_date) {
        //   const to_date = new Date(this.param.to_out_due_date);
        //   filterditems = filterditems.filter(
        //     (item) => new Date(item.out_due_date) <= to_date
        //   );
        // }
        this.$refs.grid.loadData(filterditems, ['delivery_date', 'delivery_due_date', 'request_date', 'produce_due_date', 'produce_date', 'out_date', 'out_due_date']);
        this.$refs.grid.setPage(page);
        if (items.length === 0) {
          this.settings.errorMessage = '진행중인 사항이 없습니다';
        }
      } catch (err) {
        this.error = err.message;
      } finally {
        this.isFetching = false;
      }
    },

    getToday(v) {
      const today = new Date();
      const date = new Date

      if (v == 0){
        date.setMonth(today.getMonth() + 1);
        date.setDate(0)
      }else
      {
        date.setMonth(today.getMonth() - (v - 1));
        date.setDate(1)
      };

      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let month2 = month < 10 ? '0' + month : month;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();

      return year + '-'+ month2 + '-' + day;
    },


    reset() {
      this.param = {
        lotNo: '',
        request_name: '',
        request_code: '',
        pick_name: '',
        pageSize: process.env.VUE_APP_DEFAULT_PAGE_LX_SIZE || 100000,
        code: '',
        request_company: '',
        produce_due_date: '',
        out_due_date: '',
        from_produce_due_date: '',
        to_produce_due_date: '',
        from_out_due_date: '',
        to_out_due_date: '',
        from_req_date: '',
        to_req_date: '',
      };
      this.items = [];
    },
    dbClick(data) {
      // console.log(data,"dbclick data");
      this.$emit('dbClick', _.filter(this.items, v => v.lotNo === data.lotNo)[0]);
    },
    async click(data) {
      // console.log(data,"click data");
      if (data.cellType !== "header") {
        try {
          const row = this.$refs.grid.getJsonRows();
          const coaPath = row[data.itemIndex].coapdf_path;
          const msdsPath = row[data.itemIndex].msdspdf_path;
          
          if (data.fieldName == 'coapdf_path' && coaPath) {
            // console.log("coa");
            let paths = coaPath.split(',');
            for (let path of paths) {
              path = path.trim();
              await getCoapdfFile(path);
            }
          } else if (data.fieldName == 'msdspdf_path' && msdsPath) {
            // console.log("msds");
            let paths = msdsPath.split(',');
            for (let path of paths) {
              path = path.trim();
              await getMsdspdfFile(path);
            }
          }
        } catch (error) {
          console.log('error', error);
        }
      }
      
    },
    toggleSearchOptions() {
      this.rotationDegrees += 180; // 매 클릭마다 180도 추가
      this.showSearchOptions = !this.showSearchOptions;
      // this.isRotated = !this.isRotated;
      this.resizeGrid();
    },
    resizeGrid() {
      const totalHeight = window.innerHeight; // 현재 윈도우의 내부 높이
      const targetGrid = document.querySelector(`div#${this.grid}`);
      let dynamicHeight = 360;
      if (this.showSearchOptions) {
        dynamicHeight += 70;
      }
      targetGrid.style.height = `${totalHeight - dynamicHeight}px`; // 그리드 컨테이너의 높이 조정
    },
  },
  components: {
    RealGrid,
    SetPopup,
  },
  mounted() {    
    this.param.from_req_date = this.getToday(3); 
    this.param.to_req_date = this.getToday(0);
    this.loadData();
     
    this.resizeGrid(); // 초기 로드 시 그리드 크기 조절
    window.addEventListener('resize', this.resizeGrid); // 윈도우 크기 변경 시 그리드 크기 조절
  },
  computed: {
    rotationStyle() {
      return {
        transition: 'transform 0.3s ease',
        transform: `rotate(${this.rotationDegrees}deg)`,
      };
    },
  },
};
</script>
<style lang="scss">
img.rotate {
  transition: transform 0.3s ease; /* 부드러운 변환 효과 */
  transform: rotate(180deg); /* 180도 회전 */
}
</style>
