<template>
  <div>
    <SetPopup ref="pwPopup" />
    <SetPopup ref="pwConfirm" />
    <div class="service">
      <!-- <h3 class="mb-2">비밀번호 관리</h3> -->
      <v-form ref="form" lazy-validation>
        <div class="filter-box filter-box--row">
          <ul class="filter-list_free">
            <li style="width: 180px">
              <h4>계정 구분</h4>
              <v-select :items="this.roleType" v-model="input.roles" outlined :id="'account'"></v-select>
            </li>
            <li style="width: 180px">
              <h4>이름</h4>
              <v-text-field outlined dense placeholder="" v-model="input.memberName" :rules="[this.validSet.name]" @keyup.enter="onApprove"></v-text-field>
            </li>
            <li style="width: 300px">
              <h4>이메일 주소</h4>
              <v-text-field outlined dense v-model="input.email" placeholder="" @keyup.enter="onApprove"></v-text-field>
            </li>
            <li style="width: 180px">
              <h4>기업명</h4>
              <v-text-field outlined dense v-model="input.company" placeholder="" :rules="[this.validSet.company]" @keyup.enter="onApprove"></v-text-field>
            </li>
            <li style="width: 180px">
              <h4>사번</h4>
              <v-text-field outlined dense v-model="input.employeeCode" placeholder="" :rules="[this.validSet.employNumber]" @keyup.enter="onApprove"></v-text-field>
            </li>
            <li style="width: 180px">
              <h4>재직</h4>
              <v-select :items="this.workType" v-model="input.employeeStatus" outlined id="work"></v-select>
            </li>
          </ul>
          <div class="btn-box">
            <v-card-actions>
              <v-btn depressed class="btn-reset" @click="reset">초기화</v-btn>
            </v-card-actions>
            <v-card-actions>
              <v-btn depressed class="btn-search" @click="onApprove">검색</v-btn>
            </v-card-actions>
          </div>
        </div>
        <!-- <div class="filter mb-3 sch pr-8 pt-4 pl-3">
          <v-row class="row mb-7">
            <v-col cols="12" sm="1">
              <h4>계정 구분</h4>
              <v-select
                :items="this.roleType"
                v-model="input.roles"
                outlined
                :id="'account'"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="2">
              <h4>이름</h4>
              <v-text-field
                outlined
                dense
                placeholder="이름을 입력해 주세요."
                v-model="input.memberName"
                :rules="[this.validSet.name]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <h4>이메일 주소</h4>
              <v-text-field
                outlined
                dense
                v-model="input.memberId"
                placeholder="이메일 주소를 입력해 주세요."
                :rules="[this.validSet.email]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <h4>기업명</h4>
              <v-text-field
                outlined
                dense
                v-model="input.company"
                placeholder="기업명을 입력해 주세요."
                :rules="[this.validSet.company]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <h4>사번</h4>
              <v-text-field
                outlined
                dense
                v-model="input.employeeCode"
                placeholder="사번을 입력해 주세요."
                :rules="[this.validSet.employNumber]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <h4>재직</h4>
              <v-select
                :items="this.workType"
                v-model="input.employeeStatus"
                outlined
                id="work"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="1">
              <div class="wrapperTop pl-6 pt-6">
                <v-card-actions>
                  <v-btn depressed outlined @click="reset">초기화</v-btn>
                </v-card-actions>
                <v-card-actions>
                  <v-btn depressed color="primary" @click="onApprove"
                    >검색</v-btn
                  >
                </v-card-actions>
              </div>
            </v-col>
          </v-row>
        </div> -->
      </v-form>
      <h3 class="mt-2 mb-2 dp--row">
        <!-- 목록 -->
        <v-btn style="margin-left: auto" depressed class="btn-add" @click="resetPw">비밀번호 초기화</v-btn>
      </h3>
      <RealGrid :domName="grid" ref="grid" :settings="settings" @changePage="loadData" />
    </div>
  </div>
</template>
<script>
import { columns, fields, rows } from '@/assets/grid/accountPw';
import SetPopup from '@/components/SetPopup.vue';
import RealGrid from '@/components/RealGrid.vue';
import { mapMutations, mapState } from 'vuex';
import { memberList, resetPass } from 'api/member/member';
import _ from 'lodash';
import validSet from '@/assets/valid';
export default {
  data() {
    return {
      isFetching: false,
      isDataLoaded: false,
      input: {
        employeeStatus: '전체',
        roles: '',
        memberName: '',
        email: '',
        company: '',
        employeeCode: '',
      },
      check: false,
      settings: {
        columns,
        fields,
        rows,
        height: 650,
        radio: true,
        errorMessage: '조회된 내용이 없습니다',
      },
      param: {},
      grid: 'password',
      validSet,
    };
  },
  watch: {
    param: {
      deep: true,
      handler: function () {
        this.currentPage = 1;
      },
    },
  },
  mounted() {
    this.reset();
    this.input.roles = this.roleType[0];
    this.loadData();
  },
  methods: {
    ...mapMutations('popup', ['SET_POPUP']),
    ...mapMutations('modal', ['SET_DIALOG_TITLE', 'SET_DIALOG_TEXT', 'SET_HIGHT', 'SET_MAX_WIDTH', 'SET_MODAL', 'RESET_MODAL']),

    reset() {
      this.input = {
        employeeStatus: '전체',
        roles: '전체',
        memberName: '',
        email: '',
        company: '',
        employeeCode: '',
        pageSize: 100000,
      };
    },
    loadData(v) {
      this.onApprove(v);
    },
    async onApprove(v) {
      if (this.isFetching) return;
      this.isFetching = true;

      try {
        const param = _.cloneDeep(this.input);
        switch (param.employeeStatus) {
          case '재직중':
            param.employeeStatus = 1;
            break;
          case '퇴사':
            param.employeeStatus = 2;
            break;
          case '전체':
            param.employeeStatus = '';
            break;
        }
        if (_.isNumber(v)) {
          this.currentPage = v;
        }
        const res = await memberList({
          ...param,
          currentPage: this.currentPage,
        });
        this.isDataLoaded = true;

        const response = res.data;
        const items = response.data.items;
        const page = response.data.params;
        this.items = items;
        _.each(items, v => {
          switch (v.employee_status) {
            case 1:
              v.work = '재직';
              break;
            case 2:
              v.work = '퇴사';
              break;
          }
        });
        this.$refs.grid.setPage(page);
        this.$refs.grid.loadData(items);
      } catch (err) {
        this.error = err.message;
      } finally {
        this.isFetching = false;
      }
    },
    resetPw() {
      const check = this.$refs.grid.getCheckedRow();
      if (check.length > 0) {
        this.openPopup('선택한 아이디의 비밀번호를 초기화 하시겠습니까?', true, this.resetExecRun);
      } else {
        this.openPopup('초기화할 아이디를 선택해주세요');
      }
    },
    openPopup(text, closable, cb) {
      this.SET_POPUP({
        title: '알림',
        // height: 150,
        width: 300,
        approveName: closable ? '초기화' : '확인',
        text,
        closable,
      });
      this.$refs.pwPopup.openPopup(cb);
    },
    cancel() {
      this.param = {};
      this.$refs.pwPopup.closePopup();
    },
    resetExecRun() {
      const row = this.$refs.grid.getCheckedRow()[0];
      const resetEmail = row.data.email;

      // console.log('resetEmail', resetEmail);

      resetPass(resetEmail)
        .then(res => {
          const body = res.data;
          // console.log('body', body);
          if (!_.isEmpty(body.errorCode)) {
            this.openPopup(`Error(${body.errorCode}) : 관리자에게 문의하세요`);
          } else {
            this.openPopup(body.message);
          }
        })
        .catch(err => {
          this.openPopup(`Error(${err}) : 관리자에게 문의하세요`);
        });
    },
  },
  computed: {
    ...mapState('select', ['workType', 'roleType']),
  },
  components: {
    SetPopup,
    RealGrid,
  },
};
</script>
<style>
.service {
  margin: auto;
  width: 100%;
}

.filter .v-select__slot > div.v-select__selections {
  position: absolute;
  top: 0;
}
div.v-input__slot > fieldset {
  height: 46px !important;
}

div.v-select__slot > div.v-input__append-inner > div {
  position: absolute;
  top: 10px;
  right: 0;
}
</style>
