const config = {
  // DEV
  // baseURL: 'https://smdedev.dongwha.com:8080/',
  // exelLocation: 'https://smdedev.dongwha.com',

  //PRODUCT
  baseURL: 'https://smde.dongwha.com:8080/',
  exelLocation: 'https://smde.dongwha.com',

  // LOCAL
  // baseURL: 'https://localhost:8080',
  // exelLocation: 'https://localhost:8080',
  // timeout: 10000,
};

export default config;
