import { ValueType } from 'realgrid';
import _ from 'lodash';
export function makeCell(count, value, fields, columns, width, editable, renderer, color = false) {  
  for (var v = 1; v <= count; v++) {
    {      
      _.each(value, o => {
        const field = o.field;
        const alias = o.alias;
        let excelKey;
        if (o.excelKey) {
          v > 1 ? (excelKey = `${o.excelKey}${v - 1}`) : (excelKey = o.excelKey.slice(0, -1));
        }
        const indexExclusive = o.indexExclusive;
        const indexExclusive2 = o.indexExclusive2;
        let headerText = '';
        if (indexExclusive) {
          headerText = alias ? `${alias}` : `${field}`;
        } else {
          if (indexExclusive2) {
            headerText = alias ? `${alias}${v}` : `${field}${v}`;
          }else
          {
            headerText = alias ? `${alias}` : `${field}${v}`;
          }
          
        }
        fields.push({
          fieldName: indexExclusive ? `${field}` : `${field}${v}`,
          dataType: ValueType.TEXT,
          excelKey,
        });
        columns.push({
          name: `${field}${v}`,
          fieldName: indexExclusive ? `${field}` : `${field}${v}`,
          // key: indexExclusive ? `${field}` : `${field}${v}`,
          key: field,
          type: 'data',
          styles: {
            textAlignment: 'center',
          },
          // Cell 마우스 클릭 시 드롭다운 이벤트 실행.
          editor: {
            type: alias === 'w/w' ? 'dropdown' : '',
            textReadOnly: alias === 'w/w' ? true : '',
            domainOnly: alias === 'w/w' ? true : '',
            dropDownWhenClick: alias === 'w/w' ? true : '',
          },
          lookupDisplay: true,
          labels: alias === 'w/w' ? ['Y', 'N'] : '',
          values: alias === 'w/w' ? [1, 0] : '',
          header: {
            text: headerText,
            showTooltip: true,
            styleName: 
              headerText === 'salt1' ? 'green-color' : 
              headerText === 'salt2' ? 'green-color' : 
              headerText === 'salt3' ? 'green-color' : 
              headerText === 'salt_1' ? 'green-color' : 
              headerText === 'salt_2' ? 'green-color' : 
              headerText === 'salt_3' ? 'green-color' : 
              headerText === 'M,wt%1' ? 'green-color' : 
              headerText === 'M,wt%2' ? 'green-color' : 
              headerText === 'M,wt%3' ? 'green-color' : 
              headerText === 'M,wt%_1' ? 'green-color' : 
              headerText === 'M,wt%_2' ? 'green-color' : 
              headerText === 'M,wt%_3' ? 'green-color' : 
              headerText === 'salt' ? 'green-color' : 
              headerText === 'M,wt%' ? 'green-color' : 
              headerText === '결과입력' ? 'green-color' : 
              headerText === 'wt%' && field === 'saltVol' ? 'green-color' : 
              headerText === 'wt%' && field === 'r_saltVol' ? 'green-color' : 
              headerText === '스펙명' && color ? 'disabled-color' : 
              headerText === '단위' && color ? 'disabled-color' : 
              headerText === '요청 SPEC' && color ? 'disabled-color' : 
              headerText === 'lot No' && color ? 'disabled-color' : 
              headerText === 'w/w' && color ? 'disabled-color' : 
              headerText === 'solvent' && color ? 'disabled-color' : 
              headerText === 'vol%' && color ? 'disabled-color' : 
              headerText === 'salt' && color ? 'disabled-color' : 
              headerText === 'M,wt%' && color ? 'disabled-color' : 
              headerText === 'add' && color ? 'disabled-color' : 
              headerText === 'wt%' && color ? 'disabled-color' : 
              headerText,
          },
          styleName: 
            headerText === 'salt1' ? 'green-color' : 
            headerText === 'salt2' ? 'green-color' : 
            headerText === 'salt3' ? 'green-color' : 
            headerText === 'salt_1' ? 'green-color' : 
            headerText === 'salt_2' ? 'green-color' : 
            headerText === 'salt_3' ? 'green-color' : 
            headerText === 'M,wt%1' ? 'green-color' : 
            headerText === 'M,wt%2' ? 'green-color' : 
            headerText === 'M,wt%3' ? 'green-color' : 
            headerText === 'M,wt%_1' ? 'green-color' : 
            headerText === 'M,wt%_2' ? 'green-color' : 
            headerText === 'M,wt%_3' ? 'green-color' : 
            headerText === '/' ? 'green-color' : 
            headerText === 'salt' ? 'green-color' : 
            headerText === 'M,wt%' ? 'green-color' : 
            headerText === '결과입력' ? 'green-color' : 
            headerText === 'wt%' && field === 'saltVol' ? 'green-color' : 
            headerText === 'wt%' && field === 'r_saltVol' ? 'green-color' : 
            headerText === '스펙명' && color ? 'disabled-color' : 
            headerText === '단위' && color ? 'disabled-color' : 
            headerText === '요청 SPEC' && color ? 'disabled-color' : 
            headerText === 'lot No' && color ? 'disabled-color' : 
            headerText === 'w/w' && color ? 'disabled-color' : 
            headerText === 'solvent' && color ? 'disabled-color' : 
            headerText === 'vol%' && color ? 'disabled-color' : 
            headerText === 'salt' && color ? 'disabled-color' : 
            headerText === 'M,wt%' && color ? 'disabled-color' : 
            headerText === 'add' && color ? 'disabled-color' : 
            headerText === 'wt%' && color ? 'disabled-color' : 
            headerText,

          width: o.width ? o.width : '200px',
          fillWidth: o.width ? 0 : 1,
          editable: editable ? editable : false,
          visible: headerText === 'idx' ? false : true,
          resizable: true,
          renderer,
        });
      });
    }
  }
}

export function makeGroup(data, start, end, name) {
  const ret = [];
  const items = [];
  _.each(data, (v, i) => {
    if (i >= start && i < end) {
      items.push(v.field + '1');
    } else if (i === end) {
      items.push(v.field + '1');
      ret.push({
        name,
        items,
        direction: 'horizontal',
        header: {
          text: name,
        },
      });
    } else {
      ret.push(v.field + '1');
    }
  });
  return ret;
}
export function makeRowSet(fields) {
  _.reduce(
    fields,
    (a, v) => {
      if (v.excelKey) {
        a.push({
          key: v.fieldName,
          value: v.excelKey,
        });
      }
      return a;
    },
    [],
  );
}

export function filterExel(input) {
  const row = { ...input };
  row['ww'] = row['w/w'];
  row['solventVol1'] = row['vol%'];
  row['solventVol2'] = row['vol%_1'];
  row['solventVol3'] = row['vol%_2'];
  row['solventVol4'] = row['vol%_3'];
  row['solventVol5'] = row['vol%_4'];
  row['solventVol6'] = row['vol%_5'];
  row['solventVol7'] = row['vol%_6'];
  row['solventVol8'] = row['vol%_7'];
  row['solventVol9'] = row['vol%_8'];
  row['solventVol10'] = row['vol%_9'];
  row['saltVol1'] = row['M,wt%'];
  row['saltVol2'] = row['M,wt%_1'];
  row['saltVol3'] = row['M,wt%_2'];
  row['saltVol4'] = row['M,wt%_3'];
  row['saltVol5'] = row['M,wt%_4'];
  row['addVol1'] = row['wt%'];
  row['addVol2'] = row['wt%_1'];
  row['addVol3'] = row['wt%_2'];
  row['addVol4'] = row['wt%_3'];
  row['addVol5'] = row['wt%_4'];
  row['addVol6'] = row['wt%_5'];
  row['addVol7'] = row['wt%_6'];
  row['addVol8'] = row['wt%_7'];
  row['addVol9'] = row['wt%_8'];
  row['addVol10'] = row['wt%_9'];
  row['addVol11'] = row['wt%_10'];
  row['addVol12'] = row['wt%_11'];
  row['addVol13'] = row['wt%_12'];
  row['addVol14'] = row['wt%_13'];
  row['addVol15'] = row['wt%_14'];
  return row;
}
export function blankRow(input = {}) {
  const row = { ...input };
  row['ww'] = 0;
  row['solventVol1'] = row['vol%'] || null;
  row['solventVol2'] = row['vol%_1'] || null;
  row['solventVol3'] = row['vol%_2'] || null;
  row['solventVol4'] = row['vol%_3'] || null;
  row['solventVol5'] = row['vol%_4'] || null;
  row['solventVol6'] = row['vol%_5'] || null;
  row['solventVol7'] = row['vol%_6'] || null;
  row['solventVol8'] = row['vol%_7'] || null;
  row['solventVol9'] = row['vol%_8'] || null;
  row['solventVol10'] = row['vol%_9'] || null;
  row['saltVol1'] = row['M,wt%'] || null;
  row['saltVol2'] = row['M,wt%_1'] || null;
  row['saltVol3'] = row['M,wt%_2'] || null;
  row['saltVol4'] = row['M,wt%_3'] || null;
  row['saltVol5'] = row['M,wt%_4'] || null;
  row['addVol1'] = row['wt%'] || null;
  row['addVol2'] = row['wt%_1'] || null;
  row['addVol3'] = row['wt%_2'] || null;
  row['addVol4'] = row['wt%_3'] || null;
  row['addVol5'] = row['wt%_4'] || null;
  row['addVol6'] = row['wt%_5'] || null;
  row['addVol7'] = row['wt%_6'] || null;
  row['addVol8'] = row['wt%_7'] || null;
  row['addVol9'] = row['wt%_8'] || null;
  row['addVol10'] = row['wt%_9'] || null;
  row['addVol11'] = row['wt%_10'] || null;
  row['addVol12'] = row['wt%_11'] || null;
  row['addVol13'] = row['wt%_12'] || null;
  row['addVol14'] = row['wt%_13'] || null;
  row['addVol15'] = row['wt%_14'] || null;
  return row;
}
export function getNum(val) {
  if (isNaN(val)) {
    return 0;
  }
  return val;
}
export function actSetNewSum(row) {
  let sum = 0;
  sum = Number(getNum(row['solventVol1'])) + Number(getNum(row['solventVol2'])) + Number(getNum(row['solventVol3'])) + Number(getNum(row['solventVol4'])) + Number(getNum(row['solventVol5'])) + Number(getNum(row['solventVol6'])) + Number(getNum(row['solventVol7'])) + Number(getNum(row['solventVol8'])) + Number(getNum(row['saltVol1'])) + Number(getNum(row['saltVol2'])) + Number(getNum(row['saltVol3'])) + Number(getNum(row['saltVol4'])) + Number(getNum(row['saltVol5']));
  row['sum'] = sum.toFixed(2);
  return row;
}
export function setNewSum(row) {
  let sum = 0;
  sum = Number(getNum(row['solventVol1'])) + Number(getNum(row['solventVol2'])) + Number(getNum(row['solventVol3'])) + Number(getNum(row['solventVol4'])) + Number(getNum(row['solventVol5'])) + Number(getNum(row['solventVol6'])) + Number(getNum(row['solventVol7'])) + Number(getNum(row['solventVol8'])) + Number(getNum(row['solventVol9'])) + Number(getNum(row['solventVol10'])) + Number(getNum(row['saltVol1'])) + Number(getNum(row['saltVol2'])) + Number(getNum(row['saltVol3'])) + Number(getNum(row['saltVol4'])) + Number(getNum(row['saltVol5'])) + Number(getNum(row['addVol1'])) + Number(getNum(row['addVol2'])) + Number(getNum(row['addVol3'])) + Number(getNum(row['addVol4'])) + Number(getNum(row['addVol5'])) + Number(getNum(row['addVol6'])) + Number(getNum(row['addVol7'])) + Number(getNum(row['addVol8'])) + Number(getNum(row['addVol9'])) + Number(getNum(row['addVol10'])) + Number(getNum(row['addVol11'])) + Number(getNum(row['addVol12'])) + Number(getNum(row['addVol13'])) + Number(getNum(row['addVol14'])) + Number(getNum(row['addVol15']));
  row['sum'] = sum.toFixed(2);
  return row;
}
export function checkRowEmptcheck(row) {
  return !_.isEmpty(row['solventVol1']) && !_.isEmpty(row['saltVol1']);
}
export function makeARow(fields) {
  const rowSet = _.reduce(
    fields,
    (a, v) => {
      if (v.excelKey) {
        a.push({
          key: v.fieldName,
          value: v.excelKey,
        });
      } else {
        a.push({
          key: v.fieldName,
        });
      }
      return a;
    },
    [],
  );
  return [
    _.reduce(
      rowSet,
      (a, v) => {
        a[v.key] = '';
        return a;
      },
      {},
    ),
  ];
}
export function makeSum(row) {
  const key = _.keys(row).filter(v => v.includes('Vol'));
  let sum = 0;
  _.each(key, function (v) {
    sum += row[v];
  });
  row.sum = sum.toFixed(2);
  return row;
}

export function makeSampleSet(rows, lotNo, memberId) {
  const key = ['spec', 'unit', 'vol', 'low', 'mid', 'hig', 'memberId'];
  let ret = [];
  _.each(rows, function (row) {
    const data = {};
    for (let idx = 0; idx < key.length; idx++) {
      if (key[idx] === 'memberId') {
        data[key[idx]] = memberId; // memberId 값 할당
      } else {
        data[key[idx]] = row[idx] || ''; // row[idx] 값이 존재하면 할당, 그렇지 않으면 빈 문자열 할당
      }
    }
    data.lotNo = lotNo;
    ret.push(data);
  });
  return ret;
}

export function makeCOASpec(rows, lotNo, code) {
  const key = ['spec', 'low', 'hig', 'unit', 'rv'];

  let ret = [];
  _.each(rows, function (row) {
    const data = {};
    _.each(row, (col, idx) => {
      data[key[idx]] = col;
    });
    data.lotNo = lotNo;
    data.code = code;
    ret.push(data);
  });
  return ret;
}

export function showSampleSet(row) {
  if (row && row.data) {
    return JSON.parse(row.data);
  }
}

export function isEmptyOrWhitespace(value) {
  // 값이 null 또는 undefined 인지 체크
  if (value === null || value === undefined) {
    return true;
  }

  // 값이 문자열인 경우, trim() 메서드를 사용하여 앞뒤 공백을 제거한 후 비어 있는지 체크
  if (typeof value === 'string') {
    return value.trim() === '';
  }

  // 위의 조건에 해당하지 않는 경우, false 반환 (비어 있지 않음)
  return false;
}
