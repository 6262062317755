<template>
  <div class="deliveryReportDetail">
    <!-- <h3 class="mb-2">납품일보 상세</h3> -->
    <CodeSearch
      ref="code"
      :data="codeList"
      @selectCode="
        v => {
          this.codeGrade = v;
        }
      "
    />
    <SetPopup ref="confirm" />
    <SetDialog ref="postModalAddressPT">
      <AddressPTSearch :searchRequest="this.searchRequest" @closeModalAddressPT="this.closeAddressPT" @onApprove="this.approveAddressPT" :key="approveAddressPTKey" ref="approveAddressPT"></AddressPTSearch>
    </SetDialog>

    <RealGrid :oneLineGrid="true" :domName="grid" ref="grid" :settings="this.stat !== 2 ? settingsEditable : settings" :none-page="true" @keypressEvent="onKeyPress" @changeData="compareRowAndGrid" />

    <v-form ref="form" lazy-validation>
      <div class="filter-box filter-box--row mt-6">
        <ul class="filter-list">
          <li>
            <h4>Code Grade</h4>
            <div class="dp--row">
              <v-text-field type="text" v-model="codeGrade" outlined dense disabled filled />
              <v-btn depressed class="ml-1 mr-1 btn-add" :disabled="this.stat === 2 || this.data.stat === 2" @click="read">검색</v-btn>
              <v-btn depressed class="btn-save" @click="newCode" :disabled="this.codeGradeExist || buttonDisabled || this.stat === 2">신규생성</v-btn>
              <v-alert v-if="message.length > 0" dense outlined type="error" class="error-alerts"><span v-for="(msg, index) in message" :key="index" v-text="msg" /></v-alert>
            </div>
          </li>
        </ul>
      </div>
      <div class="filter-box--row mt-6 mb-0">
        <div class="filter-box" style="">
          <ul class="filter-list_address">
            <li>
              <h4 class="">분류</h4>
              <v-text-field placeholder="" type="text" outlined dense :disabled="this.stat === 2 || this.data.stat === 2" :filled="this.stat === 2 || this.data.stat === 2" v-model="param.salestype" :style="{ width: '100%' }" />
            </li>
          </ul>
          <ul class="filter-list02" style="margin-top: 30px">
            <li>
              <h4 class="">요청자 연락처<span style="color: blue; font-weight: bold">(- 포함)</span></h4>
              <v-text-field outlined dense v-model="param.phone1" placeholder="- 를 포함 입력" :filled="this.stat === 2 || this.data.stat === 2" :disabled="this.stat === 2 || this.data.stat === 2" :rules="[this.validSet.phoneRules(true, param.phone1)]" minlength="11" maxlength="13" />
            </li>
            <li>
              <h4>요청 업체명</h4>
              <v-text-field outlined disabled filled dense placeholder="" v-model="param.request_company"></v-text-field>
            </li>
            <li>
              <h4>요청자</h4>
              <v-text-field outlined dense placeholder="" v-model="param.request_name" disabled filled></v-text-field>
            </li>
          </ul>
          <ul class="filter-list02" style="margin-top: 30px">
            <li>
              <h4>수령자 연락처<span style="color: blue; font-weight: bold">(- 포함)</span></h4>
              <v-text-field outlined dense :filled="this.stat === 2 || this.data.stat === 2" :disabled="this.stat === 2 || this.data.stat === 2" placeholder="- 를 포함 입력" v-model="param.phone2" :rules="[this.validSet.phoneRules(false, param.phone2)]" minlength="11" maxlength="13"></v-text-field>
            </li>
            <li>
              <h4>수령업체명</h4>
              <v-text-field outlined dense :filled="this.stat === 2 || this.data.stat === 2" :disabled="this.stat === 2 || this.data.stat === 2" placeholder="" v-model="param.pickpart"></v-text-field>
            </li>
            <li>
              <h4>수령자</h4>
              <v-text-field outlined dense :filled="this.stat === 2 || this.data.stat === 2" :disabled="this.stat === 2 || this.data.stat === 2" placeholder="" v-model="param.pick_name" :rules="[this.validSet.empty(param.pick_name, '수령자를 입력해주세요')]"></v-text-field>
            </li>
          </ul>
          <ul class="filter-list_address" style="margin-top: 30px">
            <li>
              <h4>요청 자재코드</h4>
              <v-text-field outlined dense v-model="param.request_code" :filled="this.stat === 2 || this.data.stat === 2" :disabled="this.stat === 2 || this.data.stat === 2"></v-text-field>
            </li>
          </ul>
        </div>

        <div class="filter-box">
          <ul class="filter-list04">
            <li>
              <h4>요청일</h4>
              <v-text-field outlined dense placeholder="" v-mask="'####-##-##'" v-model="param.request_date" disabled filled></v-text-field>
            </li>
            <li class="in_check_box">
                <h4 class="check_cancel">샘플취소</h4>
                <v-checkbox class="checkbox_cancel" v-model="param.cancel">
                </v-checkbox>
                <h4 class="check_cancel">COA 필요</h4>
                <v-checkbox class="checkbox_cancel" v-model="param.coawant" :filled="this.stat === 2 || this.data.stat === 2" :disabled="this.stat === 2 || this.data.stat === 2">
                </v-checkbox>
                <h4 class="check_cancel">서류만 요청</h4>
                <v-checkbox class="checkbox_cancel" v-model="param.doconly" :filled="this.stat === 2 || this.data.stat === 2" :disabled="this.stat === 2 || this.data.stat === 2">
                </v-checkbox>
            </li>
            <li>
              <h4 class="flex v-application justify-space-between"><span>제조 예정일</span> <v-btn v-if="param.produce_due_date !== undefined && param.produce_date == undefined" depressed class="btn-confim dongwha-btn-sm" @click="calendarRemove('produce_due_date')">제조 예정일 삭제</v-btn></h4>
              <v-menu 
                v-model="picker_1" 
                :close-on-content-click="true" 
                :nudge-right="40" 
                transition="scale-transition" 
                offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field 
                    class="default_cursor"
                    v-model="param.produce_due_date" 
                    placeholder="" 
                    append-icon="mdi-calendar" 
                    outlined 
                    dense 
                    readonly
                    :filled="filled" 
                    :disabled="filled" 
                    v-bind="attrs" 
                    v-on="on"
                    >
                  </v-text-field>
                </template>
                <v-date-picker 
                  no-title v-model="param.produce_due_date" 
                  @input="menu = false">
                </v-date-picker>
              </v-menu>
            </li>
            <li>
              <h4 class="flex v-application justify-space-between"><span>제조 완료일</span> <v-btn v-if="param.produce_date !== undefined" depressed class="btn-confim dongwha-btn-sm" @click="calendarRemove('produce_date')">제조 완료일 삭제</v-btn></h4>
              <v-menu v-model="picker_2" 
                :close-on-content-click="true" 
                :nudge-right="40" 
                transition="scale-transition" 
                offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field 
                    class="default_cursor" 
                    v-model="param.produce_date" 
                    placeholder="" 
                    append-icon="mdi-calendar" 
                    outlined 
                    dense 
                    readonly 
                    v-bind="attrs" v-on="on" 
                    :disabled="produceDateChk || filled || !codeGrade" 
                    :filled="filled"></v-text-field>
                </template>
                <v-date-picker 
                  no-title v-model="param.produce_date" 
                  @input="menu = false">
                </v-date-picker>
              </v-menu>
            </li>
            <li>
              <h4 class="flex v-application justify-space-between"><span>출하 예정일</span> <v-btn v-if="param.out_due_date !== undefined && param.out_date == undefined" depressed class="btn-confim dongwha-btn-sm" @click="calendarRemove('out_due_date')">출하 예정일 삭제</v-btn></h4>
              <v-menu v-model="picker_3" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field  class="default_cursor" v-model="param.out_due_date" placeholder="" append-icon="mdi-calendar" outlined dense readonly v-bind="attrs" v-on="on" :filled="filled" :disabled="filled"></v-text-field>
                </template>
                <v-date-picker no-title v-model="param.out_due_date" @input="menu = false"></v-date-picker>
              </v-menu>
            </li>
            <li>
              <h4 class="flex v-application justify-space-between"><span>출하 완료일</span> <v-btn v-if="param.out_date !== undefined" depressed class="btn-confim dongwha-btn-sm" @click="calendarRemove('out_date')">출하 완료일 삭제</v-btn></h4>
              <v-menu v-model="picker_4" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field  class="default_cursor" v-model="param.out_date" placeholder="" append-icon="mdi-calendar" outlined dense readonly v-bind="attrs" v-on="on" :disabled="filled || !codeGrade" :filled="filled"></v-text-field>
                </template>
                <v-date-picker no-title v-model="param.out_date" @input="menu = false"></v-date-picker>
              </v-menu>
            </li>
            <li>
              <h4 class="flex v-application justify-space-between"><span>납기 예정일</span><v-btn v-if="param.delivery_due_date !== undefined && param.delivery_date == undefined" depressed class="btn-confim dongwha-btn-sm" @click="calendarRemove('delivery_due_date')">납기에정일 삭제</v-btn></h4>
              <v-menu v-model="picker_5" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field  class="default_cursor" v-model="param.delivery_due_date" placeholder="" append-icon="mdi-calendar" outlined dense readonly v-bind="attrs" v-on="on" :filled="filled" :disabled="filled"></v-text-field>
                </template>
                <v-date-picker no-title @input="menu = false" v-model="param.delivery_due_date"></v-date-picker>
              </v-menu>
            </li>
            <li>
              <h4 class="flex v-application justify-space-between"><span>납기일</span></h4>
              <v-menu v-model="picker_6" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <!-- <v-text-field v-model="param.delivery_date" placeholder="" append-icon="mdi-calendar" outlined dense readonly v-bind="attrs" v-on="on" :disabled="filled || !codeGrade" :filled="filled"></v-text-field> -->
                  <v-text-field  class="default_cursor" v-model="param.delivery_date" placeholder="" append-icon="mdi-calendar" outlined dense readonly v-bind="attrs" v-on="on" :disabled="true" filled></v-text-field>
                </template>
                <v-date-picker no-title @input="menu = false" v-model="param.delivery_date"></v-date-picker>
              </v-menu>
            </li>
          </ul>
        </div>
      </div>

      <div class="filter-box--row">
        <div class="filter-box" style="padding-bottom: 28px">
          <ul class="filter-list04">
            <li>
              <h4>Qty(kg)</h4>
              <v-text-field outlined dense :filled="this.stat === 2 || this.data.stat === 2" :disabled="this.stat === 2 || this.data.stat === 2" placeholder="" v-model="param.qty"></v-text-field>
            </li>
            <li>
              <h4>유/무상</h4>
              <v-select :items="code.P" v-model="param.price_type" placeholder="선택" outlined :filled="this.stat === 2 || this.data.stat === 2" :disabled="this.stat === 2 || this.data.stat === 2" id="work" style="width: 100%"></v-select>
            </li>
            <li>
              <h4>포장 용기</h4>
              <v-select :items="this.rmAll(code.C)" v-model="param.packing" placeholder="선택해주세요" outlined :filled="this.stat === 2 || this.data.stat === 2" :disabled="this.stat === 2 || this.data.stat === 2" id="work"></v-select>
            </li>
            <li>
              <h4>배송방법</h4>
              <v-select :items="this.rmAll(code.D)" v-model="param.delivery_type" placeholder="선택해주세요" outlined id="work" :filled="this.stat === 2 || this.data.stat === 2" :disabled="this.stat === 2 || this.data.stat === 2"></v-select>
            </li>
          </ul>
          <ul class="filter-list_address">
            <li class="dp--collums" style="margin-top: 30px">
              <h4>배송지 선택</h4>
              <div class="dp--row" style="width: 100%; align-items: flex-start; gap: 0">
                <v-text-field placeholder="" type="text" outlined dense filled disabled v-model="param.address" :rules="[this.validSet.empty(param.address, '주소를 입력해주세요')]" />
                <v-btn depressed class="ml-1 btn-address-search" :filled="this.stat === 2 || this.data.stat === 2" :disabled="this.stat === 2 || this.data.stat === 2" @click="searchAddress">주소검색</v-btn>
                <v-btn depressed class="ml-1 btn-address-search btn-sample-request" @click="searchAddressPT">배송지 검색</v-btn>
              </div>
            </li>
            <li style="margin-top: 10px">
              <h4 class="">상세주소*</h4>
              <v-text-field placeholder="" type="text" outlined dense :filled="this.stat === 2 || this.data.stat === 2" :disabled="this.stat === 2 || this.data.stat === 2" v-model="param.address2" :rules="[this.validSet.empty(param.address2, '상세 주소를 입력해주세요')]" />
            </li>
          </ul>
        </div>
        <div class="filter-box">
          <ul class="filter-list_address">
            <li>
              <h4>분석 특이사항</h4>
              <v-text-field outlined dense v-model="param.report_analysis" :filled="this.stat === 2 || this.data.stat === 2" :disabled="this.stat === 2 || this.data.stat === 2"></v-text-field>
            </li>
            <li style="margin-top: 30px">
              <h4>고객요청 특이사항</h4>
              <v-text-field outlined dense v-model="param.request_etc" :filled="this.stat === 2 || this.data.stat === 2" :disabled="this.stat === 2 || this.data.stat === 2"></v-text-field>
            </li>
            <li style="margin-top: 30px">
              <h4>제조상 특이사항</h4>
              <v-text-field outlined dense v-model="param.produce_etc" :filled="this.stat === 2 || this.data.stat === 2" :disabled="this.stat === 2 || this.data.stat === 2"></v-text-field>
            </li>
            <li style="margin-top: 30px">
              <h4>포장 및 배송관련 특이사항</h4>
              <v-text-field outlined dense v-model="param.delivery_etc" :filled="this.stat === 2 || this.data.stat === 2" :disabled="this.stat === 2 || this.data.stat === 2"></v-text-field>
            </li>
          </ul>
        </div>
      </div>
      <div class="wrapper wrapper-br">
        <div class="btn-box">
          <v-card-actions>
            <v-btn depressed class="btn-cancle" @click="cancle">취소</v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-btn depressed class="btn-confim" :disabled="isDisabled" @click="save">완료 </v-btn>
          </v-card-actions>
        </div>
      </div>
    </v-form>
  </div>
</template>
<script>
import _ from 'lodash';
import { scrollToTop } from '@/utils/common_utils';
import { columns, fields, rows, height } from '@/assets/grid/sampleRequestLotNo';
import { mapState, mapMutations } from 'vuex';
import RealGrid from '@/components/RealGrid.vue';
import CodeSearch from '@/components/CodeSearch.vue';
import SetPopup from '@/components/SetPopup.vue';
import validSet from '@/assets/valid';
import { searchproduce } from 'api/sample/sample';
import { updateSampleStat, produceupdate, produceupdate2, codedbsearch, codedbcreate } from 'api/sample/sample';
import SetDialog from '@/components/SetDialog.vue';
import AddressPTSearch from '@/components/AddressPTSearch.vue';
import moment from 'moment';

export default {
  props: ['data'],
  data() {
    const outDateExists = 'out_date' in this.data; // 출하 완료일이 입력된 경우 수정 불가
    const statCheck = this.data.stat;

    const doconly = this.data.doconly;
    const coawant = this.data.coawant;

    if (coawant == 1) {
      this.coawant = true;
    }
    
    if (doconly == 1) {
      this.doconly = true;
    }

    let editableColumns = _.cloneDeep(columns);
    if (statCheck == 2) {
      this.cancel = true;
      editableColumns.map(column => {
        column.editable = false;
        return column;
      });
    }

    if (statCheck != 2) {
      editableColumns.map(column => {
        // 조성 정보 lotNo 빼고 모든 컬럼 수정가능
        if (!outDateExists && column.fieldName !== 'lotNo') {
          column.editable = true;
        } else {
          column.editable = false;
        }
        return column;
      });
    }
    return {
      approveAddressPTKey: 0,
      isFetching: false,
      isDataLoaded: false,
      isSame: true,
      id: '',
      isDisabled: true,
      codeGrade: this.data.code ? this.data.code : '',
      codeGradeExist: true,
      buttonDisabled: false,
      produceReport: [],
      message: [],
      cancel: false, // stat 처음 기본값
      cancelChanged: false,
      produceDateChk: false,
      stat: 0,
      val: 0,
      filled: this.data.stat == 2 ? true : false,
      coawant: this.data.coawant == 1 ? true : false,
      doconly: this.data.doconly == 1 ? true : false,
      codeList: [],
      picker_1: false,
      picker_2: false,
      picker_3: false,
      picker_4: false,
      picker_5: false,
      picker_6: false,
      validSet,
      grid: 'delivaryReportDetail',
      gridRow: {},
      gridUpdateChk: false,
      gridPairChk: true,
      // matchingItems: {},
      settings: {
        columns,
        fields,
        rows,
        height,
        hideCheckBar: true,
        noneNo: true,
      },
      settingsEditable: {
        columns: editableColumns,
        fields,
        rows,
        height,
        hideCheckBar: true,
        noneNo: true,
      },
      param: {
        salestype: this.data.salestype, // 분류
        request_date: this.data.request_date, // 요청일
        request_name: this.data.request_name, // 요청자
        request_company: this.data.request_company, // 요청업체명
        request_company_code: this.data.request_company_code, // 요청업체명코드
        produce_due_date: this.data.produce_due_date, //  제조 예정일
        produce_date: this.data.produce_date, // 제조 완료일
        out_date: this.data.out_date, // 출하 완료일
        out_due_date: this.data.out_due_date, // 출하 예정일
        delivery_due_date: this.data.delivery_due_date, // 납기 예정
        pickpart: this.data.pickpart, // 수령업체명
        price_type: this.data.price_type2, // 유 무상
        qty: this.data.qty2, // qty
        delivery_date: this.data.delivery_date, // 납기 완료
        ww: this.data.ww,
        stat: this.data.stat,
        cancel: this.cancel,
        coawant,
        doconly,
        phone1: this.data.phone1,
        phone2: this.data.phone2,
        pick_name: this.data.pick_name,
        address: this.data.address,
        address2: this.data.address2,
        postcode: this.data.postcode,
        packing: this.data.packing, // 포장 용기
        delivery_type: this.data.delivery_type, // 배송 방법
        request_code: this.data.request_code, // 요청 자재코드
        report_analysis: this.data.report_analysis, // 분석 특이사항
        request_etc: this.data.request_etc, // 고객요청 특이사항
        produce_etc: this.data.produce_etc, // 제조상 특이 사항
        delivery_etc: this.data.delivery_etc, // 포장 및 배송 관련 특이사항
      },
      searchRequest: {},
    };    
  },
  methods: {
    ...mapMutations('popup', ['SET_POPUP', 'SET_POPUP_TEXT']),
    ...mapMutations('menu', ['REMOVE_SELECT_MENU']),
    ...mapMutations('member', ['SET_USER_INFO']),
    ...mapMutations('modal', ['SET_DIALOG_TITLE', 'SET_DIALOG_TEXT', 'SET_HIGHT', 'SET_MAX_WIDTH', 'SET_MODAL', 'RESET_MODAL']),

    valid() {
      return this.$refs.form.validate();
    },
    setModal(msg, closable, cb) {
      this.SET_POPUP({
        title: '알림',
        // height: 150,
        width: 300,
        closable: closable,
        text: msg,
      });
      this.$refs.confirm.openPopup(cb);
    },
    setModalAutoClose(msg, closable, cb, autoclose) {
      this.SET_POPUP({
        title: '조성 데이터가 누락되었습니다.',
        // height: 150,
        width: 350,
        closable: closable,
        text: msg,
      });
      this.$refs.confirm.openPopupAutoClose(cb, autoclose, 1800);
      scrollToTop();
    },
    read() {
      this.columnKeyValueChecked();

      const row = this.$refs.grid.getJsonRow();
      const lotNo = this.data.lotNo;
      this.checkPairs(row);
      this.gridUpdateChk = false;
      this.buttonDisabled = false;
      this.isDisabled = false;

      if (!this.gridPairChk) {
        this.setModalAutoClose('성분명과 볼륨은 세트로 입력되야 합니다.<br>누락된 데이터를 입력해주세요.', false, null, true);
        return;
      }

      if (this.gridPairChk) {
        codedbsearch({
          lotNo,
          memberId: this.id,
          ...row,
        })
          .then(res => {
            const data = res.data.data;
            if (data) {
              this.codeGradeExist = true;
              if (data.list) {
                //여러개
                const list = data.list;
                this.codeList = list;
                this.searchCode();
              } else {
                //1개
                this.codeGrade = data.CODE;
              }
              this.message = []; // 초기화. ['']를 쓰지 않는다.
            } else {
              this.setModal('동일 목록이 없습니다', false, () => {});
              this.message = ['동일 목록이 없습니다. Code Grade를 신규 생성해 주세요.'];
              this.codeGradeExist = false;
            }
          })
          .catch(() => {});
      }
    },
    reset() {
      this.codeGradeExist = true;
      this.codeList = [];
      this.message = [];
      this.param = {
        salestype: this.data.salestype,
        request_date: this.data.request_date,
        request_name: this.data.request_name,
        request_company: this.data.request_company,
        request_company_code: this.data.request_company_code,        
        produce_due_date: this.data.produce_due_date,
        produce_date: this.data.produce_date,
        out_date: this.data.out_date,
        out_due_date: this.data.out_due_date,
        delivery_due_date: this.data.delivery_due_date,
        pickpart: this.data.pickpart,
        price_type: this.data.price_type2,
        qty: this.data.qty,
        delivery_date: this.data.delivery_date,
        phone1: this.data.phone1,
        phone2: this.data.phone2,
        pick_name: this.data.pick_name,
        address: this.data.address,
        address2: this.data.address2,
        postcode: this.data.postcode,
        packing: this.data.packing,
        delivery_type: this.data.delivery_type,
        request_code: this.data.request_code,
        report_analysis: this.data.report_analysis,
        request_etc: this.data.request_etc,
        produce_etc: this.data.produce_etc,
        delivery_etc: this.data.delivery_etc,
      };
    },
    searchCode() {
      this.$refs.code.open();
    },
    cancle() {
      this.setModal('취소하시겠습니까', true, () => {
        this.reset();
        this.REMOVE_SELECT_MENU('delivaryReportDetail');
      });
    },
    calendarRemove(field) {
      // 잘못 선택한경우 삭제 방법이 없어서 기능을 추가함(조제예정일)
      if (confirm('등록된 날짜를 삭제하시겠습니까?<br>변경된 날짜는 하단 "완료" 버튼 클릭 후 적용을 해 주셔야 합니다.')) {
        this.param[field] = undefined;
      }
    },
    columnKeyValueChecked() {
      this.$refs.grid.checkKeyValuePairs(0, 2);
    },
    save() {
      const row = this.$refs.grid.getJsonRow();
      this.columnKeyValueChecked();
      this.checkPairs(row);
      if (!this.gridPairChk) {
        this.setModalAutoClose('성분명과 볼륨은 세트로 입력되야 합니다.<br>누락된 데이터를 입력해주세요.', false, null, true);
        return;
      }
      // 기존 코드 그레이드 있는 경우.
      if (this.oldCodeGrade != '' && this.oldCodeGrade != undefined) {
        // 조성항목 변경이 되고 신규 코드가 없는 경우.
        if (!this.isSame && (this.codeGrade == '' || this.codeGrade == undefined)) {
          this.setModal('조성항목 변경 : <br>Code Grade 재검색 또는 신규 생성을 하셔야 합니다.');
          scrollToTop();
          return;
        }
      }
      if (this.valid() && this.gridPairChk) {
        // && this.cancelChanged
        // 그리드 업데이트하지 않았을 시,
        if (this.gridUpdateChk) {
          // 그리드 업데이트 시,
          if (this.oldCodeGrade != '' && this.oldCodeGrade != undefined && !this.codeGrade) {
            this.message = ['Code Grade 재검색 또는 신규 생성을 하셔야 합니다.'];
            scrollToTop();
          }
        }
        // if (this.gridUpdateChk) { 문에 있던 내용을 이동하였음.
        this.setModal('저장 하시겠습니까', true, () => {
          this.saveExec();
        });
      }
    },
    newCode() {
      this.gridUpdateChk = false;
      this.produceDateChk = false;
      const row = this.$refs.grid.getJsonRow();
      this.checkPairs(row);
      if (this.gridPairChk) {
        codedbcreate({
          lotNo: this.data.lotNo,
          memberId: this.id,
          ...row,
        })
          .then(res => {
            if (res.data.errorMessage) {
              this.setModal(res.data.errorMessage);
            } else {
              this.codeGrade = res.data.data.code;
              this.message = []; // 초기화  [''];
            }
          })
          .catch(err => {
            this.setModal(err.errorMessage);
          });
      }
    },
    async saveExec() {
      const row = this.$refs.grid.getJsonRow();

      if (this.val == 2) {
        updateSampleStat({
          stat: this.val,
          lotNo: this.data.lotNo,
          memberId: this.id,
        })
          .then(() => {})
          .catch(() => {});
      } else if (this.val == 1) {
        updateSampleStat({
          stat: this.val,
          lotNo: this.data.lotNo,
          memberId: this.id,
        })
          .then(() => {})
          .catch(() => {});
      }


      // console.log(this.param, 'this.param');
      if (this.codeGrade === '') {
        produceupdate2({
          ...this.param,
          ...row,
          code: '',
          lotNo: this.data.lotNo,
          memberId: this.id,
        })
          .then(() => {
            this.REMOVE_SELECT_MENU('delivaryReportDetail');
          })
          .catch(err => {
            this.setModal(err);
          });
      } else {
        produceupdate({
          ...this.param,
          ...row,
          code: this.codeGrade,
          lotNo: this.data.lotNo,
          memberId: this.id,
        })
          .then(() => {
            this.REMOVE_SELECT_MENU('delivaryReportDetail');
          })
          .catch(err => {
            this.setModal(err);
          });
      }
    },
    populateGridRow() {
      // 납품일보목록 에서 가져온 조성정보를 따로 저장
      const keys = Object.keys(this.data);
      keys.forEach(key => {
        const solvent = key.startsWith('solvent') && parseInt(key.replace('solvent', '')) <= 10;
        const solventVol = key.startsWith('solventVol') && parseInt(key.replace('solventVol', '')) <= 10;
        const salt = key.startsWith('salt') && parseInt(key.replace('salt', '')) <= 5;
        const saltVol = key.startsWith('saltVol') && parseInt(key.replace('saltVol', '')) <= 5;
        const add = key.startsWith('add') && parseInt(key.replace('add', '')) <= 15;
        const addVol = key.startsWith('addVol') && parseInt(key.replace('addVol', '')) <= 15;

        if (solvent || solventVol || salt || saltVol || add || addVol || key === 'ww') {
          this.gridRow[key] = this.data[key];
        }
      });
    },
    onKeyPress(val) {
      this.produceDateChk = val;
    },
    compareRowAndGrid() {
      this.isSame = true; // 조성 변화 없다고 가정 하에 시작한다.

      this.codeGrade = '';
      this.buttonDisabled = true;

      let row = this.$refs.grid.getJsonRow();
      let rowReSet = {};
      for (let r in row) {
        if (r == 'lotNo') continue;
        if (row[r] != undefined && row[r] != '') {
          if (r == 'ww' || r.startsWith('solventVol') || r.startsWith('saltVol') || r.startsWith('addVol')) {
            rowReSet[r] = parseFloat(row[r]); // parseInt(row[r]);
          } else {
            rowReSet[r] = row[r];
          }
        }
      }

      // const keys = new Set([...Object.keys(row), ...Object.keys(this.gridRow)]); // 두개 합치는거임
      const keysToCompare = Object.keys(this.gridRow); // 원본 조성 데이터.
      let rowReSetCount = Object.keys(rowReSet).length;
      let gridRowCount = Object.keys(this.gridRow).length;

      if (rowReSetCount !== gridRowCount) {
        this.isSame = false;
      } else {
        for (let key of keysToCompare) {
          if (this.gridRow[key] != rowReSet[key]) {
            this.isSame = false;
            break;
          }
        }
      }

      if (this.isSame) {
        // isSame true면 조성정보가 모두 같음 / 변경된게 없다.
        this.gridUpdateChk = false;
        this.codeGrade = this.oldCodeGrade;
        this.isDisabled = true;
        this.message = []; // 조성 변경이 없는 경우 메세지 삭제.
      } else {
        this.gridUpdateChk = true;
        this.codeGrade = '';
        this.isDisabled = false; // 조성 물질 변경 시 저장 버튼 활성화.
        // 기존 코드 값이 있는 경우.
        if (this.oldCodeGrade != '' && this.oldCodeGrade != undefined) {
          this.message = ['조성 항목 변경. Code Grade 재검색 또는 신규 생성해야 합니다.'];
        }
      }
    },
    trueValue(val) {
      // 체크박스 체크 되었을때
      this.val = val;
      this.stat = 2;
      // eslint-disable-next-line vue/no-mutating-props
      this.data.stat = this.stat;
      this.$refs.grid.setColumnsEditable(); // 입력 못하게 막음
      this.$refs.grid.setRowStyleCallback();
    },
    falseValue(val) {
      this.val = val;
      this.stat = 1;
      // eslint-disable-next-line vue/no-mutating-props
      this.data.stat = this.stat;
      const outDateExists = 'out_date' in this.data;
      if (!outDateExists) {
        this.$refs.grid.setColumnsEditable2(); // 입력 풀기
        this.$refs.grid.setRowStyleCallback2();
      }
    },
    searchAddress() {
      new window.daum.Postcode({
        oncomplete: data => {
          this.param.address = data.address;
          this.param.address2 = data.address2;
          this.param.postcode = data.zonecode;
        },
      }).open();
    },
    isBlank(str) {
      return !str || /^\s*$/.test(str);
      // null, undefined, "" 경우 true 반환
    },
    checkPairs(row) {
      const result = [];
      for (let i = 1; i <= 10; i++) {
        const solvent = 'solvent' + i;
        const solventVol = 'solventVol' + i;

        if ((row[solvent] && !this.isBlank(row[solvent])) || (row[solventVol] && !this.isBlank(row[solventVol]))) {
          if (!(row[solvent] && !this.isBlank(row[solvent]) && row[solventVol] && !this.isBlank(row[solventVol]))) {
            this.gridPairChk = false;
            return [];
          }
          if (row[solvent] && !this.isBlank(row[solvent]) && !row[solventVol] && !this.isBlank(row[solventVol])) {
            this.gridPairChk = false;
            return [];
          }
          result.push({
            [solvent]: row[solvent],
            [solventVol]: row[solventVol],
          });
        }
      }
      // salt와 saltVol 페어 확인
      for (let i = 1; i <= 5; i++) {
        const salt = 'salt' + i;
        const saltVol = 'saltVol' + i;

        if ((row[salt] && !this.isBlank(row[salt])) || (row[saltVol] && !this.isBlank(row[saltVol]))) {
          if (!(row[salt] && !this.isBlank(row[salt]) && row[saltVol] && !this.isBlank(row[saltVol]))) {
            this.gridPairChk = false;
            return [];
          }
          if (row[salt] && !this.isBlank(row[salt]) && !row[saltVol] && !this.isBlank(row[saltVol])) {
            this.gridPairChk = false;
            return [];
          }
          result.push({
            [salt]: row[salt],
            [saltVol]: row[saltVol],
          });
        }
      }
      // add와 addVol 페어 확인
      for (let i = 1; i <= 15; i++) {
        const add = 'add' + i;
        const addVol = 'addVol' + i;

        if ((row[add] && !this.isBlank(row[add])) || (row[addVol] && !this.isBlank(row[addVol]))) {
          if (!(row[add] && !this.isBlank(row[add]) && row[addVol] && !this.isBlank(row[addVol]))) {
            this.gridPairChk = false;
            return [];
          }
          if (row[add] && !this.isBlank(row[add]) && !row[addVol] && !this.isBlank(row[addVol])) {
            this.gridPairChk = false;
            return [];
          }
          result.push({
            [add]: row[add],
            [addVol]: row[addVol],
          });
        }
      }
      this.gridPairChk = true;
      return result;
    },
    async loadData() {
      if (this.isFetching) return;
      this.isFetching = true;

      try {
        const response = await searchproduce({
          viewName: 'delivery',
          lotNo: this.data.lotNo,
        });

        this.isDataLoaded = true;
        this.isSame = true;
        this.codeGradeExist = true;
        this.buttonDisabled = false;
        this.message = [];
        this.cancel = false;
        this.coawant = false;
        this.doconly = false;
        this.cancelChanged = false;
        this.produceDateChk = false;
        this.stat = 0;
        this.val = 0;
        

        this.filled = this.data.stat == 2 ? true : false;
        // this.codeList = [];
        this.picker_1 = false;
        this.picker_2 = false;
        this.picker_3 = false;
        this.picker_4 = false;
        this.picker_5 = false;
        this.picker_6 = false;
        this.gridUpdateChk = false;
        this.gridPairChk = false;
        this.gridRow = {}; // 서버 통신 후 새로운 데이터로 교체 전 초기화.

        // const response = res.data;
        
        const items = response.data.data.items;

        // console.log(items, 'items');
        
        const item = items[0];
        this.$refs.grid.loadData(items);
        this.codeGrade = item.code; // 코드
        this.oldCodeGrade = item.code; // 코드 원본
        this.param.salestype = item.salestype; // 분류
        this.param.request_company = item.request_company; // 요청업체명
        this.param.request_company_code = item.request_company_code; // 요청업체명
        this.param.request_name = item.request_name; // 요청자
        this.param.phone1 = item.phone1; // 요청자 연락처
        this.param.phone2 = item.phone2; // 수령자 연락처
        this.param.pickpart = item.pickpart; // 수령업체명
        this.param.pick_name = item.pick_name; // 수령자
        this.param.address = item.address; // 주소
        this.param.address2 = item.address2; // 상세주소
        this.param.request_date = item.request_date; // 요청일
        //(this.param.cancel = item.cancel), // 샘플취소
        this.param.produce_due_date = item.produce_due_date; //  제조 예정일
        this.param.produce_date = item.produce_date; // 제조 완료일
        this.param.out_due_date = item.out_due_date; // 출하 예정일
        this.param.out_date = item.out_date; // 출하 완료일
        this.param.delivery_due_date = item.delivery_due_date; // 납기 예정일
        this.param.delivery_date = item.delivery_date; // 납기일
        this.param.qty = item.qty2; // qty
        this.param.price_type = item.price_type2; // 유 무상
        this.param.packing = item.packing; // 포장용기
        this.param.delivery_type = item.delivery_type; // 배송 방법
        this.param.request_code = item.request_code; // 요청 자재코드
        this.param.report_analysis = item.report_analysis; // 분석 특이사항
        this.param.request_etc = item.request_etc; // 고객요청 특이사항
        // searchproduce에서 병합되어 내려온다.
        this.param.produce_etc = item.produce_etc; // 제조상 특이 사항
        this.param.delivery_etc = item.delivery_etc; // 포장 및 배송 관련 특이사항
        // 병합되어 내려오니 아래 코드는 필요 없을듯.
        // if (this.matchingItems) {
        //   this.param.produce_etc = this.matchingItems.produce_etc; // 제조상 특이 사항
        //   this.param.delivery_etc = this.matchingItems.delivery_etc; // 포장 및 배송 관련 특이사항
        // }
        this.param.ww = item.ww; // ww
        // this.param.stat= item.stat, // 샘플 상태
        this.param.postcode = item.postcode; // 우편번호

        this.populateGridRow();
        this.$refs.grid.allRemoveUserCellStyle(); // 라인 색상 클래스 삭제.
        scrollToTop();
        // 제조완료일 없는 경우.
        if (item.produce_date == '' || item.produce_date == undefined || item.produce_date == null) {
          this.$refs.grid.setColumnsEditable2();
          this.$refs.grid.setRowStyleCallbackDefault();
        } else {
          this.$refs.grid.setColumnsEditable();
          this.$refs.grid.setRowStyleCallback();
        }
      } catch (err) {
        this.error = err.message;
      } finally {
        this.isFetching = false;
      }
    },
    closeModalAddressPT() {},
    searchAddressPT() {

      const searchRequest = {
        ptCd: this.param.request_company_code,
        pickname: '',
        open_yn: 'Y',
        search_yn: 'Y',
        currentPage: 1,
        pageSize: process.env.VUE_APP_DEFAULT_PAGE_LX_SIZE || 100000,
      };

      this.SET_MODAL({
        // height: 600,
        width: 800,
        closable: true,
        customApprove: true,
      });

      this.searchRequest = searchRequest;
      this.$refs.postModalAddressPT.openModal();
    },
    closeAddressPT() {
      this.$refs.postModalAddressPT.closeModal();
      this.approveAddressPTKey = moment().valueOf();
    },
    approveAddressPT(address) {
      if (address) {
        this.param.pick_name = address.pickname;
        this.param.phone2 = address.phone2;
        this.param.postcode = address.postcode;
        this.param.address = address.address1;
        this.param.address2 = address.address2;
      }
    },
  },
  watch: {
    'param.cancel'(val) {
      this.cancelChanged = true;
      if (val) {
        // 체크 박스 체크 되었을떄
        const val = 2;
        this.filled = true;
        this.param.stat = 2;
        this.trueValue(val);
      } else {
        // 체크 박스 미 체크
        const val = 1;
        this.filled = false;
        this.param.stat = 1;
        this.falseValue(val);
      }
    },
    // eslint-disable-next-line no-unused-vars
    'param.produce_date'(val) {
      //제조완료일 없는 경우
      if (val == undefined || val == '' || val == null) {
        this.$refs.grid.setColumnsEditable2();
        this.$refs.grid.setRowStyleCallbackDefault();
      } else {
        this.$refs.grid.setColumnsEditable(); // 입력 못하게 막음
        this.$refs.grid.setRowStyleCallback();
      }
    },
    'param.coawant'(val) {
      this.cancelChanged = true;
      if (val) {
        // 체크 박스 체크 되었을떄
        const val = 1;
        this.coawant = true;
        this.param.coawant = 1;        
      } else {
        // 체크 박스 미 체크
        const val = 0;
        this.coawant = false;
        this.param.coawant = 0;        
      }
    },
    'param.doconly'(val) {
      this.cancelChanged = true;
      if (val) {
        // 체크 박스 체크 되었을떄
        const val = 1;
        this.doconly = true;
        this.param.doconly = 1;
        
      } else {
        // 체크 박스 미 체크
        const val = 0;
        this.doconly = false;
        this.param.doconly = 0;
      }
    },
    param: {
      deep: true, // 지정한 속성안에 있는 속성의 값, 안에 삽인된 속성의 값도 감시
      // eslint-disable-next-line no-unused-vars
      handler: function (newVal, oldVal) {
        // param이 변경될때마다 실행
        for (const key in newVal) {
          if (newVal[key] !== this.data[key]) {
            // 데이터 비교
            this.isDisabled = false;
            return;
          }
        }
        this.isDisabled = true;
      },
    },
  },
  computed: {
    ...mapState('common', ['code']),
  },
  mounted() {
    this.id = this.$store.state.member.userInfo.memberId;
    this.gridRow = {}; // 다시 초기화

    // 이 부분은 새로운 데이터 fetch 후 하는 것으로 이동한다.
    // this.populateGridRow();
    this.loadData();
    const produceDateExists = 'produce_date' in this.data; // 제조완료일
    if (produceDateExists) {
      this.$refs.grid.setColumnsEditable();
      this.$refs.grid.setRowStyleCallback();
    }
  },
  components: {
    RealGrid,
    CodeSearch,
    SetPopup,
    SetDialog,
    AddressPTSearch,
  },
};
</script>
<style lang="scss">
.deliveryReportDetail {
  .v-input__slot {
    width: 100%;
  }
  .dongwha-btn-sm.v-btn.v-size--default {
    height: 24px !important;
    padding: 3px !important;
  }
  .dongwha-btn-sm .v-btn__content {
    font-size: 0.8em;
  }
  .error-alerts {
    padding: 5px;
    margin-bottom: 0px;
    margin-left: 10px;
  }
}
.v-alert {
  font-size: inherit !important;
}
.wrapper-br {
  padding-bottom: 30px;
}
.search-button {
  background-color: #f5f5f5;
  border-radius: 50%;
  padding: 8px;
  margin-left: 8px;
}
.default_cursor input {
  cursor: default ;
}
</style>
