<template>
  <div class="address">
    <!-- <h3 class="mb-2">샘플요청 상세</h3> -->
    <SetPopup ref="confirm" />
    <RealGrid domName="sampleRequestDeatil" ref="grid" :settings="settings" :nonePage="true" class="" />
    <!-- <h3 class="mt-4">추가 정보</h3> -->
    <div class="filter-box--row mt-6">
      <div class="filter-box" style="padding-bottom: 76px">
        <ul class="filter-list04">
          <li>
            <h4>요청자</h4>
            <v-text-field outlined dense v-model="param.request_name" placeholder="" disabled filled></v-text-field>
          </li>
          <li>
            <h4>요청업체명</h4>
            <v-text-field outlined dense v-model="param.request_company" placeholder="" disabled filled></v-text-field>
          </li>
          <li>
            <h4>요청자 연락처*</h4>
            <v-text-field outlined dense v-model="param.phone1" placeholder="" disabled filled></v-text-field>
          </li>
          <li>
            <h4>수령자 연락처</h4>
            <v-text-field outlined dense v-model="param.phone2" placeholder="" disabled filled></v-text-field>
          </li>
          <li>
            <h4>수령자</h4>
            <v-text-field outlined dense v-model="param.pick_name" placeholder="" disabled filled></v-text-field>
          </li>
          <li>
            <h4>수령업체명</h4>
            <v-text-field outlined dense v-model="param.pick_company" placeholder="" disabled filled></v-text-field>
          </li>
          <li>
            <h4>우편번호</h4>
            <v-text-field type="text" :value="data.postcode" outlined dense disabled filled />
          </li>
          <li>
            <h4>배송지주소</h4>
            <v-text-field type="text" :value="(data.address ? data.address : '') + ` ` + (data.address2 ? data.address2 : '')" outlined dense disabled filled />
          </li>
        </ul>
      </div>
      <div class="filter-box">
        <ul class="filter-list02">
          <li>
            <h4>요청 자재코드</h4>
            <v-text-field outlined dense placeholder="" v-model="param.request_code"></v-text-field>
          </li>
          <li>
            <h4>Qty(kg)</h4>
            <v-text-field outlined dense placeholder="" v-model="param.qty"></v-text-field>
          </li>
          <li>
            <h4>유무상</h4>
            <v-select :items="code.P" v-model="param.price_type" placeholder="선택" outlined id="work"></v-select>
          </li>
          <li>
            <h4>배송방법</h4>
            <v-select :items="code.D" v-model="param.delivery_type" placeholder="선택" outlined id="work"></v-select>
          </li>
          <li>
            <h4>포장용기</h4>
            <v-select :items="code.C" v-model="param.packing" placeholder="선택" outlined id="work"></v-select>
          </li>
          <li class="in_check_box">
            <h4 class="check_cancel">COA 필요</h4>
            <v-checkbox class="checkbox_cancel" v-model="param.coawant">
            </v-checkbox>
            <h4 class="check_cancel">서류만 요청</h4>
            <v-checkbox class="checkbox_cancel" v-model="param.doconly">
            </v-checkbox>
          </li>
          
        </ul>
        <ul class="filter-list_address">
          <li style="margin-top: 30px">
            <h4>분석 요청사항</h4>
            <v-text-field outlined dense placeholder="" v-model="param.analysis"></v-text-field>
          </li>
          <li style="margin-top: 30px">
            <h4>기타 요청사항</h4>
            <v-textarea outlined rows="4" class="filter-textarea" dense placeholder="" v-model="param.etc"></v-textarea>
          </li>
        </ul>
      </div>
    </div>
    <div class="wrapper">
      <div class="wrapper btn-box mb-2">
        <v-card-actions>
          <v-btn depressed class="btn-cancle" @click="cancle">취소</v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-btn depressed class="btn-confim" color="primary" @click="request">완료</v-btn>
        </v-card-actions>
      </div>
    </div>
  </div>
</template>
<script>
import { columns, fields, rows } from '@/assets/grid/sampleRequestLotNo';
import { sampleSearch, updateSample } from 'api/sample/sample';
import { mapMutations, mapState } from 'vuex';
import RealGrid from '@/components/RealGrid.vue';
import SetPopup from '@/components/SetPopup.vue';
// import Address from '@/components/Address.vue';
import _ from 'lodash';
export default {
  props: ['data'],
  watch: {
    'param.same': function (v) {
      if (v) {
        this.param.pick_name = this.param.request_name;
      }
    },
    'param.same2': function (v) {
      if (v) {
        this.param.pick_company = this.param.request_company;
      }
    },
    'param.default': function (v) {
      if (v === 0) {
        this.param.address = this.defaultAddress.address;
        this.param.address2 = this.defaultAddress.address2;
        this.param.postcode = this.defaultAddress.postcode;
      } else {
        this.param.address = '';
        this.param.address2 = '';
        this.param.postcode = '';
      }
    },
  },
  data() {
    return {
      isFetching: false,
      isDataLoaded: false,
      settings: {
        columns: _.map(_.cloneDeep(columns), function (v) {
          v.editable = true;
          return v;
        }),
        fields,
        rows,
        hideCheckBar: true,
        noneNo: true,
        cancelChanged: false,
      },
      fileName: '',
      param: {
        default: 0,
        postcode: this.data.postcode,
        phone1: this.data.phone1,
        phone2: this.data.phone2,
        // address: this.data.address,
        // address2: this.data.address2,
        address: '',
        address2: '',
        // postcode: '',
        price_type: '',
        packing: '',
        delivery_type: '',
        request_name: '',
        pick_name: '',
        request_code: '',
        analysis: '',
        etc: '',
        pick_company: '',
        qty: '',
        request_company: '',
        request_date: '',
        coawant: this.coawant,
        doconly: this.doconly,
      },
      address: [
        {
          key: 'default',
          text: '기본배송지',
        },
        {
          key: 'other',
          text: '다른 배송지',
        },
      ],
    };
  },
  watch: {
    'param.coawant'(val) {
      this.cancelChanged = true;
      if (val) {
        // 체크 박스 체크 되었을떄
        const val = 1;
        this.coawant = true;
        this.param.coawant = 1;        
      } else {
        // 체크 박스 미 체크
        const val = 0;
        this.coawant = false;
        this.param.coawant = 0;        
      }

      
      // console.log(this.coawant);
      // console.log(this.param.coawant);

    },
    'param.doconly'(val) {
      this.cancelChanged = true;
      if (val) {
        // 체크 박스 체크 되었을떄
        const val = 1;
        this.doconly = true;
        this.param.doconly = 1;
        
      } else {
        // 체크 박스 미 체크
        const val = 0;
        this.doconly = false;
        this.param.doconly = 0;
      }

      // console.log(this.doconly);
      // console.log(this.param.doconly);
    },
  },

  methods: {
    ...mapMutations('popup', ['SET_POPUP']),
    ...mapMutations('menu', ['REMOVE_SELECT_MENU']),
    async loadData() {
      if (this.isFetching) return;
      this.isFetching = true;
      try {
        const res = await sampleSearch(this.data);

        this.coawant = false;
        this.doconly = false;
        this.isDataLoaded = true; // 여기에서 로드 완료 상태 업데이트

        const response = res.data;
        const items = response.data.items;
        this.$refs.grid.loadData(items);
        const item = items[0];
        // vuejs의 불변 원칙에 맞게 스프레드 연산자 코드 수정.
        this.param = { ...this.param, ...item };

        // console.log('item.coawant', item.coawant);
        // console.log('item.doconly', item.doconly);


        this.coawant = item.coawant == 1 ? true : false;
        this.doconly = item.doconly == 1 ? true : false;

        // console.log('this.coawant', this.coawant);
        // console.log('this.param.coawant', this.param.coawant);
        

        // 검수단계에서 요청일자 데이터 처리.
        const utcTimeStr = item.reg_dt; //'2024-02-21T00:41:33.000+00:00';
        const date = new Date(utcTimeStr);
        const options = { timeZone: 'Asia/Seoul', year: 'numeric', month: '2-digit', day: '2-digit' };
        const koreaTimeStr = new Intl.DateTimeFormat('ko-KR', options).format(date);
        const formattedDate = koreaTimeStr.replace(/\./g, '').trim().replace(/ /g, '-');
        this.param.request_date = formattedDate;
      } catch (err) {
        this.error = err.message;
      } finally {
        this.isFetching = false;
      }
    },
    cancle() {
      this.openPopup(
        '취소하시겠습니까',
        () => {
          this.param = {
            default: 0,
            postcode: '',
            address: '',
            price_type: '',
            packing: '',
            delivery_type: '',
            request_code: '',
            request_date: '',
            analysis: '',
            etc: '',
          };
          this.REMOVE_SELECT_MENU('sampleRequestDetail');
        },
        true,
      );
    },
    valid() {
      return true;
    },
    openPopup(text, cb, closable) {
      this.SET_POPUP({
        title: '알림',
        // height: 150,
        width: 300,
        text,
        closable,
      });
      this.$refs.confirm.openPopup(cb);
    },
    request() {
      const row = this.$refs.grid.getJsonRow();

      if (this.valid()) {
        updateSample({ ...this.param, ...row })
          .then(() => {
            this.openPopup('저장되었습니다', () => {
              this.REMOVE_SELECT_MENU('sampleRequestDetail');
            });
          })
          .catch(() => {
            this.openPopup('서버에러, 관리자에게 문의하세요', this.cancle());
          });
      }
    },
    searchAddress() {
      new window.daum.Postcode({
        oncomplete: data => {
          this.param.address = data.address;
          this.param.address2 = data.address2;
          this.param.postcode = data.zonecode;
        },
      }).open();
    },
    onAddress(data) {
      this.param.postcode = data.zonecode;
      this.param.address = data.address;
      this.param.address2 = data.address2;
    },
    addressBook() {
      this.$refs.address.open();
    },
  },
  components: {
    RealGrid,
    SetPopup,
    // Address,
  },
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapState('common', ['code']),
    ...mapState('member', ['defaultAddress']),
    otherAddress() {
      return !this.param.default;
    },
  },
  created() {
    this.debouncedLoadData = _.debounce(this.loadData, 300);
  },
};
</script>
<style lang="scss">
.fileBtn {
  height: 41px !important;
}
.file {
  .v-text-field__details {
    display: none !important;
  }
  .v-input__slot {
    width: 300px;
  }
}
.aLink {
  color: blue !important;
  text-decoration: underline;
}
.address {
  .v-input__slot {
    width: 100%;
  }
  .row {
    height: 100px;
  }
}
</style>
