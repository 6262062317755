import _ from 'lodash';
import { makeCell } from './gridUtill';
import pdfIcon from '../../assets/img/icon_pdf.png';

let fields = [];
let columns = [];
const data = [
  {
    field: 'lotNo',
    alias: 'Lot No',
  },
  {
    field: 'code',
    alias: 'Code Grade',
  },
  {
    field: 'salestype',
    alias: '분류',
    width: 300,
  },
  {
    field: 'request_date',
    alias: '요청일',
  },
  {
    field: 'produce_due_date',
    alias: '제조예정일',
  },
  {
    field: 'produce_date',
    alias: '제조완료일',
  },
  {
    field: 'out_due_date',
    alias: '출하예정일',
  },
  {
    field: 'out_date',
    alias: '출하완료일',
  },
  {
    field: 'delivery_due_date',
    alias: '납기 예정일',
  },
  {
    field: 'delivery_date',
    alias: '납기일',
  },
  {
    field: 'request_company',
    alias: '요청 업체명',
  },
  {
    field: 'request_etc',
    alias: '고객 요청 특이사항',
    width: 250,
  },
  {
    field: 'request_name',
    alias: '요청자',
  },
  {
    field: 'phone1',
    alias: '요청자 연락처',
  },
  {
    field: 'pick_name_chg',
    alias: '수령자 변경여부',
  },
  {
    field: 'pickpart',
    alias: '수령업체명',
  },
  {
    field: 'pick_name',
    alias: '수령자',
  },
  {
    field: 'phone2',
    alias: '수령자 연락처',
  },
  {
    field: 'price_type2',
    alias: '유,무상',
  },
  {
    field: 'qty2',
    alias: 'Qty(kg)',
  },
  {
    field: 'packing',
    alias: '포장용기',
  },
  {
    field: 'delivery_type',
    alias: '배송방법',
  },
  {
    field: 'request_code',
    alias: '고객요청자재코드',
    width: 200,
  },
  {
    field: 'code_title',
    alias: '품명',
    width: 500,
  },
  {
    field: 'postcode',
    alias: '우편번호',
  },
  {
    field: 'address',
    alias: '주소',
    width: 200,
  },
  {
    field: 'address2',
    alias: '상세주소',
    width: 200,
  },
  {
    field: 'resultYn',
    alias: '결과입력',
  },
  {
    field: 'coapdf_path',
    alias: 'COA',
    urlCallback: true,
    width: 180,
  },
  {
    field: 'msdspdf_path',
    alias: 'MSDS',
    urlCallback: true,
    width: 180,
  },
];

_.each(data, function (o) {
  o.indexExclusive = true;    
  if (o.urlCallback) {
      makeCell(1, [o], fields, columns, null, null, {
        type: 'html',
        callback: function (grid, cell) {
          if (cell.valueColumn.fieldName && cell.valueColumn.fieldName === 'coapdf_path') {
            if (cell.value == undefined || cell.value == '') {
              return `<a href="#"></a>`;
            } else {
              let pdfPaths = cell.value.split(',');
              let pdfIcons = `<div class="icons">`;
              let nonEmptyPdfPaths = pdfPaths.filter(path => path.trim() !== '');
              if (nonEmptyPdfPaths.length > 0) {
                pdfIcons += `<img src="${pdfIcon}" alt="pdf icon" />`;
                if (nonEmptyPdfPaths.length > 1) {
                  pdfIcons += `<span class="icon_num">` + nonEmptyPdfPaths.length + `</span>`;
                }
              }
              pdfIcons += `</div>`;
              return pdfIcons;
            }
          } else if (cell.valueColumn.fieldName && cell.valueColumn.fieldName === 'msdspdf_path') {
            if (cell.value == undefined || cell.value == '') {
              return `<a href="#"></a>`;
            } else {
              let pdfPaths = cell.value.split(',');
  
              let pdfIcons = `<div class="icons">`;  
          
              let nonEmptyPdfPaths = pdfPaths.filter(path => path.trim() !== '');
              if (nonEmptyPdfPaths.length > 0) {
                pdfIcons += `<img src="${pdfIcon}" alt="pdf icon" />`;
                if (nonEmptyPdfPaths.length > 1) {
                  pdfIcons += `<span class="icon_num">` + nonEmptyPdfPaths.length + `</span>`;
                }
              }
              pdfIcons += `</div>`;
              return pdfIcons;
            }
          }
        },
      });
    } else {
      makeCell(1, [o], fields, columns);
    }

  // makeCell(1, [o], fields, columns);
});
export { fields, columns };
